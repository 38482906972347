<template>

    <b-card no-body>
        <b-card-header>
            <b-card-title>
                Настройки
            </b-card-title>
        </b-card-header>
        
        <!-- <b-card-body>
            <b-row>
                <b-col md="6">
                    <b-card-title>
                       Тарифы (аренда)
                    </b-card-title>
                     <validation-observer ref="settings">
                        <form>
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость 1 TH/s (USD)"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость 1 TH/s (USD)</span>                             
                                    <b-form-input  placeholder="35" type="number" v-model="form.thsp" />

                                </validation-provider>
                             </b-form-group>
                            
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Потребление Ватт за 1 TH/s"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Потребление Ватт за 1 TH/s</span>                             
                                    <b-form-input  placeholder="35" type="number" v-model="form.wpt" />

                                </validation-provider>
                             </b-form-group>
                            
                             <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость 1 кВ/ч"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость 1 кВ/ч (USD)</span>                             
                                    <b-form-input  placeholder="2.5" type="number" v-model="form.kvth" />

                                </validation-provider>
                             </b-form-group>
                            
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость Э/Э на 1 TH/s"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость Э/Э на 1 TH/s (USD)</span>                             
                                    <b-form-input :value="energy"  type="number" disabled />

                                </validation-provider>
                            </b-form-group>

                            <b-card-title style="margin-top: 1.53rem">
                                Партнерская программа
                            </b-card-title>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 12-23 месяца (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 12-23 месяца (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_12_23"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_24_35"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 36 месяцев (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 36 месяцев (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_36"  />

                                </validation-provider>
                            </b-form-group>

                              <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за покупку оборудования рефералом (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за покупку оборудования рефералом (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_m"  />

                                </validation-provider>
                            </b-form-group>

                              <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Бонус рефералу на первую аренду мощности (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Бонус рефералу на первую аренду мощности (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitee_percent"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Скидка рефералу на первую покупку оборудования (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Скидка рефералу на первую покупку оборудования (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitee_percent_m"  />

                                </validation-provider>
                            </b-form-group>

                            
                            <div class="d-flex justify-content-end">
                                <b-button @click="save" variant="primary">
                                    Сохранить
                                </b-button>
                            </div>
                        </form>
                     </validation-observer>     
                                       
                </b-col>
            </b-row>
        </b-card-body> -->
        
    </b-card>
    
</template>

<script>
    import User from "@/modules/user/"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    
    export default {

        data() {
            return {
                required,
                form: {
                    
                },

                integrations : {
                    amocrm: false
                }
            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'settings' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'settings'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'settings'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {  

            
            
            save( args ) {
                 this.$refs.settings.validate().then(success => {
                     
                    if(!success) return;
                     
                    this.$request.post("app/updatePrices", this.params ).then( rsp => {
                         
                     
                         
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Готово`,
                              text: `Настройки успешно обновлены`,
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });
                    });
                     
                 });
            },

        },
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        computed: {
            params() {
                
                // let params = {
                //     'egp' : this.energy
                // };
                
                Object.keys( this.form ).forEach(key => {
                    params[key] = this.form[key];
                });
                
                return params;                
            },
            // energy() {
            //     return (this.form.kvth * (this.form.wpt / 1000)).toFixed(6);
            // }
        },
        watch: {

        },
        mounted() {
        },

    }

</script>